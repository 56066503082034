<template>
    <div class="news-container">
        <main-nav fixed></main-nav>
        <l-right-contact></l-right-contact>

        <div class="banner">
            <div>
                <div class="banner-title">颜创动态</div>
                <img ondragstart="return false" src="@/assets/images/news/banner-title-img.png">
            </div>
        </div>

        <div class="news-list">

            <a :href="`/news/${item.id}`" v-for="(item,id) in news" :key="id" class="news">
                <div class="news-thumb">
                    <img :src="item.thumb" :alt="item.title" :title="item.title">
                </div>
                <div class="news-content">
                    <div class="news-title" @click="gotoDetail(item)">{{ item.title }}</div>
                    <div class="news-summary">{{ item.summary }}</div>
                    <div class="news-data">
                        <div>
                            <img src="@/assets/images/news/icon-time.png" alt="">
                            <span>{{ item.created_at }}</span>
                        </div>
                        <div>
                            <span class="img-span"><img src="@/assets/images/news/icon-eyes.png" alt=""></span>
                            <span>{{ item.view_num }}</span>
                        </div>
                    </div>
                </div>
            </a>

            <a-pagination v-model="current" :total="total" show-less-items/>

        </div>


        <l-footer></l-footer>
        <l-icp></l-icp>
    </div>
</template>

<script>
    import MainNav from "../../components/MainNav";
    import LFooter from "../../components/LFooter";
    import LIcp from "../../components/LIcp";
    import LRightContact from "../../components/LRightContact";
    import meta from "../../meta";
    import http from "../../http";

    export default {
        name: "Index",
        components: {LRightContact, LIcp, LFooter, MainNav},
        metaInfo: meta.appendTitle('颜创动态'),
        data() {
            return {
                current: 1,
                total: 0,
                news: []
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {

                http.get('articles', {page: this.current}, (data) => {

                    this.total = data.list.total
                    this.news = data.list.data

                })

            },
            gotoDetail(item) {
                location.href = 'news/' + item.id
            }
        }
    }
</script>

<style lang="less" scoped>
    .banner {
        height: 600px;
        background-image: url("../../assets/images/news/news-bg.png");
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .banner-title {
        font-size: 40px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 56px;
        letter-spacing: 1px;
        margin-bottom: 45px;
    }

    .banner img {
        width: 450px;
    }

    .news-list {
        padding: 150px 1px;
    }

    .news {
        margin: 0 auto 85px;
        width: 1200px;
        max-width: 100%;
        display: flex;

    }

    .news-thumb {
        width: 390px;
        line-height: 0;
        margin-right: 20px;

        img {
            width: 390px;
            height: 220px;
        }
    }

    .news-content {
        text-align: left;
    }

    .news-title {
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        line-height: 45px;
        letter-spacing: 1px;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .news-summary {
        height: 115px;
        width: 794px;
        font-size: 18px;
        font-weight: 400;
        color: #999999;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .news-data {

        font-size: 16px;
        font-weight: 400;
        color: #999999;

        div {
            margin-right: 40px;
            float: left;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: flex-start;
        }

        .img-span {
            width: 30px;
        }

        span {
            line-height: 22px;
        }
    }

</style>

<style lang="less">
    .news-list {
        .ant-pagination-item {
            border: 0;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 25px;
            padding: 8px 15px;
            height: auto;
            border-radius: 0;
        }

        .ant-pagination-item-active {
            background: #0D392E;

            a {
                color: #F9F9F9;
            }
        }

        .ant-pagination-prev, .ant-pagination-next {
            a {
                border: 0;
                font-size: 18px;
            }
        }
    }
</style>